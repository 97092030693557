// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { getCurrentUser, login, logout } from '../services/authService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
    setLoading(false); // Set loading to false after checking for the current user
  }, []);

  const handleLogin = async (username, password) => {
    const data = await login(username, password);
    setUser(getCurrentUser());
    return data;
  };

  const handleLogout = () => {
    logout();
    setUser(null);
  };

  const contextValue = useMemo(() => ({
    user,
    handleLogin,
    handleLogout,
    loading,
  }), [user, loading]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
