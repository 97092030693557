import PuffLoader from "react-spinners/PuffLoader";
import { processSheet } from "../services/apiService";
import { useState } from "react";
import useFetchSettings from "../hooks/useFetchSettings";
import moment from "moment";

const Settings = () => {
  const [loadingProcess, setLoadingProcess] = useState(false);
  const { settings, reload } = useFetchSettings();

  const handleSubmit = async () => {
    setLoadingProcess(true);
    try {
      await processSheet();
      await reload();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcess(false);
    }
  }

  return (
    <div className="mx-auto max-w-6xl">
      <div className="mx-auto max-w-4xl">
        <h1 className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8 dark:text-white">Dashboard</h1>
        <p className="dark:text-white" >Last Processed Time: {settings?.lastProcessed ? moment(settings.lastProcessed).format("HH:mm | MMM D YYYY")  : "Never"}</p>
        <button
          disabled={loadingProcess}
          onClick={handleSubmit}
          type="submit"
          className="my-3 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold dark:text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          <PuffLoader speedMultiplier={1} color="white" loading={loadingProcess} size={24} />
          {!loadingProcess && 'Process'}
        </button>
      </div>
    </div>
  );
};

export default Settings;
