import { useEffect, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { updateSettings } from '../services/apiService';
import { CheckCircleIcon } from '@heroicons/react/16/solid';
// import { Listbox, ListboxOption, ListboxLabel } from './catalyst/listbox';
import useFetchSettings from '../hooks/useFetchSettings';

const Settings = () => {
  const { settings: initialSettings, loading } = useFetchSettings();
  const [settings, setSettings] = useState(initialSettings || {});
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    if (initialSettings) {
      setSettings(initialSettings);
    }
  }, [initialSettings]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingSave(true);
      // Save the settings
      await updateSettings(settings);
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <div className="mx-auto max-w-6xl">
      <form className="mx-auto max-w-4xl" onSubmit={handleSubmit}>
        <h1 className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8 dark:text-white">Settings</h1>
        <PuffLoader className="my-2" speedMultiplier={1} color="white" loading={loading} size={24} />
        {!loading && (
          <div>
            {/* <p className="mt-1 text-sm leading-6 text-gray-400">
              Configure these settings to
            </p> */}
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="instructionPrompt" className="block text-sm font-medium leading-6 dark:text-white">
                  Instruction Prompt
                </label>
                <div className="mt-2">
                  <textarea
                    value={settings.instructionPrompt || ''}
                    onChange={(e) => setSettings({ ...settings, instructionPrompt: e.target.value })}
                    id="instructionPrompt"
                    name="instructionPrompt"
                    rows={3}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-400">Write the first part, the instruction prompt for LLM</p>
              </div>

              <div className="col-span-full">
                <label htmlFor="instructionPrompt" className="block text-sm font-medium leading-6 dark:text-white">
                  Search Term Prompt
                </label>
                <div className="mt-2">
                  <textarea
                    value={settings.searchTermPrompt || ''}
                    onChange={(e) => setSettings({ ...settings, searchTermPrompt: e.target.value })}
                    id="searchTermPrompt"
                    name="searchTermPrompt"
                    rows={3}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-400">Write the second part, the search term prompt for LLM</p>
              </div>
              {/* 
              <div className="sm:col-span-4">
                <label htmlFor="llm" className="block text-sm font-medium leading-6 dark:text-white">
                  LLM API
                </label>
                <div className='mt-2'>
                  <Listbox onChange={(e) => {
                    setSettings({ ...settings, llm: e });
                  }}

                    value={settings.llm || ""}
                    name="llm"
                  >
                    <ListboxOption value="openai">
                      <ListboxLabel>OpenAI GPT-4o</ListboxLabel>
                    </ListboxOption>
                    <ListboxOption value="anthropic">
                      <ListboxLabel>Claude 3 Opus</ListboxLabel>
                    </ListboxOption>
                  </Listbox>
                </div>
              </div> */}

              <div className="sm:col-span-4">
                <label htmlFor="temperature" className="block text-sm font-medium leading-6 dark:text-white">
                  Temperature
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                    <input
                      type="number"
                      value={settings.temperature || 0.5}
                      onChange={(e) => setSettings({ ...settings, temperature: e.target.value })}
                      name="temperature"
                      id="temperature"
                      className="flex-1 block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder="0.5"
                      step={0.1}
                      min={0}
                      max={1}
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="openaiapikey" className="block text-sm font-medium leading-6 dark:text-white">
                  OpenAI API Key
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                    <input
                      type="text"
                      value={settings.openaiapikey || ''}
                      onChange={(e) => setSettings({ ...settings, openaiapikey: e.target.value })}
                      name="openaiapikey"
                      id="openaiapikey"
                      autoComplete="openaiapikey"
                      className="flex-1 block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder="abc123"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="anthropicapikey" className="block text-sm font-medium leading-6 dark:text-white">
                  Anthropic API Key
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                    <input
                      type="text"
                      value={settings.anthropicapikey || ''}
                      onChange={(e) => setSettings({ ...settings, anthropicapikey: e.target.value })}
                      name="anthropicapikey"
                      id="anthropicapikey"
                      autoComplete="anthropicapikey"
                      className="flex-1 block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder="abc123"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor='googleOAuth' className='block text-sm font-medium leading-6 dark:text-white'>
                  Google OAuth
                </label>
                <div className='mt-2'>
                  <button className="gsi-material-button" onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/auth`}>
                    <div className="gsi-material-button-state"></div>
                    <div className="gsi-material-button-content-wrapper">
                      <div className="gsi-material-button-icon">
                        <svg version="1.1" viewBox="0 0 48 48" style={{ display: 'block' }}>
                          <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                          <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                          <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                          <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                      <span className="gsi-material-button-contents">Sign in with Google</span>
                      <span style={{ display: 'none' }}>Sign in with Google</span>
                    </div>
                  </button>
                </div>
                {settings.token?.expiry_date && <div className="rounded-md bg-green-50 p-3 inline-block mt-3">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">Google OAuth Connected</h3>
                    </div>
                  </div>
                </div>}
              </div>

              <div className="sm:col-span-4">
                <label htmlFor='sheetId' className='block text-sm font-medium leading-6 dark:text-white'>
                  Sheet ID
                </label>

                <div className="mt-2">
                  <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                    <input
                      type="text"
                      value={settings.sheetId || ''}
                      onChange={(e) => setSettings({ ...settings, sheetId: e.target.value })}
                      name="sheetId"
                      id="sheetId"
                      className="flex-1 block w-full rounded-md border-0 bg-white/5 py-1.5 dark:text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder="abc123"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                disabled={loadingSave}
                type="submit"
                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold dark:text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                <PuffLoader speedMultiplier={1} color="white" loading={loadingSave} size={24} />
                {!loadingSave && 'Save'}
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Settings;
