import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import PrivateLayout from './PrivateLayout';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return null;
  }

  return user ? <PrivateLayout>{children}</PrivateLayout> : <Navigate to="/" />;
};

export default PrivateRoute;
