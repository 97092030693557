// src/services/authService.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { username, password });

    // Store the token in localStorage
    localStorage.setItem('token', response.data.token);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = () => {
  // Remove the token from localStorage
  localStorage.removeItem('token');
};

export const getCurrentUser = () => {
  const token = localStorage.getItem('token');
  if (token) {
    // Optional: decode the JWT token to get user info
    return JSON.parse(atob(token.split('.')[1]));
  }
  return null;
};

export const getToken = () => {
  return localStorage.getItem('token');
};