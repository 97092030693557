import * as Headless from '@headlessui/react'
import React from 'react'

export const Link = React.forwardRef(function Link(props, ref) {
  return (
    <Headless.DataInteractive>
      <a {...props} ref={ref} >
        {props.children || ''} 
      </a>
    </Headless.DataInteractive>
  )
})
