import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
});

// Add a request interceptor to attach the token to headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data.error === "Token expired") {
      // Redirect to root URL
      localStorage.removeItem('token');
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export const getSettings = async () => {
  try {
    const response = await api.get('/api/settings');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSettings = async (settings) => {
  try {
    const response = await api.post('/api/settings', settings);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const processSheet = async () => {
  try {
    const response = await api.post('/api/sheets/manual');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export default api;
