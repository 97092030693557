import { SidebarLayout } from './catalyst/sidebar-layout'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from './catalyst/sidebar'
import {
  ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
} from '@heroicons/react/20/solid'
import { Button } from './catalyst/button'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'

function Layout({ children }) {
  const navigate = useNavigate();
  const { handleLogout } = useContext(AuthContext);

  return (
    <SidebarLayout
      // navbar={
      //   <Navbar>
      //     <NavbarSpacer />
      //     <NavbarSection>
      //       <NavbarItem href="/search" aria-label="Search">
      //         <MagnifyingGlassIcon />
      //       </NavbarItem>
      //       <NavbarItem href="/inbox" aria-label="Inbox">
      //         <InboxIcon />
      //       </NavbarItem>
      //       <Dropdown>
      //         <DropdownButton as={NavbarItem}>
      //           <Avatar src="/profile-photo.jpg" square />
      //         </DropdownButton>
      //         <DropdownMenu className="min-w-64" anchor="bottom end">
      //           <DropdownItem href="/my-profile">
      //             <UserIcon />
      //             <DropdownLabel>My profile</DropdownLabel>
      //           </DropdownItem>
      //           <DropdownItem href="/settings">
      //             <Cog8ToothIcon />
      //             <DropdownLabel>Settings</DropdownLabel>
      //           </DropdownItem>
      //           <DropdownDivider />
      //           <DropdownItem href="/privacy-policy">
      //             <ShieldCheckIcon />
      //             <DropdownLabel>Privacy policy</DropdownLabel>
      //           </DropdownItem>
      //           <DropdownItem href="/share-feedback">
      //             <LightBulbIcon />
      //             <DropdownLabel>Share feedback</DropdownLabel>
      //           </DropdownItem>
      //           <DropdownDivider />
      //           <DropdownItem href="/logout">
      //             <ArrowRightStartOnRectangleIcon />
      //             <DropdownLabel>Sign out</DropdownLabel>
      //           </DropdownItem>
      //         </DropdownMenu>
      //       </Dropdown>
      //     </NavbarSection>
      //   </Navbar>
      // }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <SidebarItem href="/" className="text-center">
              Weeding Genie
            </SidebarItem>
            {/* <SidebarSection className="max-lg:hidden">
              <SidebarItem href="/search">
                <MagnifyingGlassIcon />
                <SidebarLabel>Search</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/inbox">
                <InboxIcon />
                <SidebarLabel>Inbox</SidebarLabel>
              </SidebarItem>
            </SidebarSection> */}
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItem href="/dashboard">
                <HomeIcon />
                <SidebarLabel>Dashboard</SidebarLabel>
              </SidebarItem>
              {/*<SidebarItem href="/events">
                <Square2StackIcon />
                <SidebarLabel>Events</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/orders">
                <TicketIcon />
                <SidebarLabel>Orders</SidebarLabel>
              </SidebarItem> */}
              <SidebarItem href="/settings">
                <Cog6ToothIcon />
                <SidebarLabel>Settings</SidebarLabel>
              </SidebarItem>
              {/* <SidebarItem href="/broadcasts">
                <MegaphoneIcon />
                <SidebarLabel>Broadcasts</SidebarLabel>
              </SidebarItem> */}
            </SidebarSection>
            {/* <SidebarSection className="max-lg:hidden">
              <SidebarHeading>Upcoming Events</SidebarHeading>
              <SidebarItem href="/events/1">Bear Hug: Live in Concert</SidebarItem>
              <SidebarItem href="/events/2">Viking People</SidebarItem>
              <SidebarItem href="/events/3">Six Fingers — DJ Set</SidebarItem>
              <SidebarItem href="/events/4">We All Look The Same</SidebarItem>
            </SidebarSection> */}
            {/* <SidebarSpacer />
            <SidebarSection>
              <SidebarItem href="/support">
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/changelog">
                <SparklesIcon />
                <SidebarLabel>Changelog</SidebarLabel>
              </SidebarItem>
            </SidebarSection> */}
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Button
              onClick={() => {
                handleLogout()
                navigate('/')
              }}
              className="w-full cursor-pointer" 
            >
              <ArrowRightStartOnRectangleIcon />
              Sign out
            </Button>
            {/* <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src="/profile-photo.jpg" className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">Erica</span>
                    <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                      erica@example.com
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/my-profile">
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/privacy-policy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/share-feedback">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
          </SidebarFooter>
        </Sidebar>
      }
    >
      {children}
    </SidebarLayout>
  )
}

export default Layout